.tabs.is-toggle li a {
    border-radius: 4px!important;
    outline: none;
}

.menu-list a {
    padding: 0.75em 1em!important;
    border-radius: 5px!important;
}

.custom-dropdown {
    position: relative;    
}

.custom-dropdown .custom-dropdown-menu {
    position: absolute;
    z-index: 1;
    left: 0.75rem;
    right: 0.75rem;
    padding-top: 4px;
    display: none;
}

.custom-dropdown:focus-within .custom-dropdown-menu {
    display: block;
}

.custom-dropdown-content {
    background: white;
    border-radius: 0.375em;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.custom-input {
    box-shadow: inset 0 0.0625em 0.125em rgba(10,10,10,.05);
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    color: #363636;
    align-items: center;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: none;
    display: block;
    justify-content: flex-start;
    position: relative;
}

.custom-input.is-only {
    max-height: 52px;
    overflow: hidden;
}

.custom-input input {
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    outline: 0;
    border: 1px solid transparent;
    width: 100%;
    font-size: 1rem;
    height: 2.5em;
    line-height: 1.5;
    background-color: transparent;
}

.custom-input:focus-within {
    border-color: #485fc7;
    box-shadow: 0 0 0 0.125em rgba(72,95,199,.25);
}

.custom-input span {
    margin-left: calc(0.75em - 1px);
    background-color: #485fc7;
    border-radius: 4px;
    padding: 5px 8px;
    color: white;
    display: inline-block;
    margin-top: 0.5rem;
}

.custom-input.is-only span {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.custom-dropdown-content span {
    cursor: pointer;
}

.custom-dropdown-content span:hover {
    background-color: #f5f5f5;
}

.clickable {
    cursor: pointer!important;
}

.custom-img {
    height: 40px;
    vertical-align: middle;
    margin: 0 0.5rem 0 0;
}

.menu ul li a i {
    height: 24px;
    width: 24px;
    vertical-align: middle;
    margin: 0 2rem 0 0;
}

input[type=date] {
    display: block;
}

.custom-disable .input[disabled],  .custom-disable .select select[disabled]{
    background-color: #fff!important;
    border-color: #dbdbdb!important;
    border-radius: 4px!important;
    color: #363636!important;
}

table.is-paddingless tr, table.is-paddingless td, table.is-paddingless th {
    padding: 0!important;
}

.menu-list a.is-active {
    background-color: #48c78e!important;
}

.with-background-white {
    background-color: white;
    padding: 1.5rem 2rem!important;
}

.custom-background {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: -1;
    background-image: url("./assets/background.jpg");
    background-size: cover;
    background-position: center;
}