#print {
    display: block;
    font-size: 18px;
    color: black;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    padding: 2rem;

    .column {
        .is-paddingless {
            padding: 0!important;
        }
    }

        table {
            font-size: 16px;
            border-collapse: collapse;
            width: 100%;

            tr {
                height: 30px;

                td {
                    border: 1px solid black;
                    padding: 5px 10px;
                }
            }
        }
    }