html {
    background-color: #f4f6f8!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden!important;
}

.main {
    height: 100vh;
    overflow-y: scroll;
}

.column.select {
    height: initial!important;

    select {
        width: 100%;
    }

    &:after {
        right: 1.625em!important;
        margin-top: .2375em!important;
    }
}

.tabs {
    ul {
        li {
            border: none!important;
        }
    }
}

table {
    &.is-borderless {
        th, td, tr {
            border: none!important;
            padding: 1.25rem 1rem;
        }
    }
}